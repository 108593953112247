.size {
  position: fixed;
  top: 100;
  left: 0;
  align-items: center;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.example {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  padding: 20px;
  background: white;


  form {
    display: flex;
    align-items: flex-start;
    margin-right: 50px;

    * {
      margin-right: 10px;
    }
  }

  h1 {
    font-size: 20px;
    font-weight: bold;
  }

  .preview {
    white-space: pre;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}